/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-link {
  color: #2a73b3;
}

.password-text {
  font-size: 12px;
}

/* Table Striped styles */
.table-striped > thead > tr {
  background-color: #4472c4 !important;
  color: white !important;
}

.table-striped > thead > tr small {
  color: white !important;
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: #cfd5ea !important;
}

.table-striped > tbody > tr:nth-child(even) {
  background-color: #e9ebf5 !important;
}

.add-new-button {
  background: #4472c4 !important;
  font-size: 14px;
  font-weight: bold;
  border-color: transparent !important;
}

/* DatePicker Component */
.react-datepicker-wrapper {
  width: 100%;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  /* opacity: 0; */
  cursor: pointer;
  /* height: 0; */
  /* width: 0; */
}

.main-heading {
  color: #204654;
  font-size: 20px;
  font-weight: 700;
}

.checkbox-container input {
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8.5px;
  top: 5px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.App {
  text-align: center;
  background-color: #f2f2f2;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* drop down overflow fix */
.navbar .dropdown-menu {
  cursor: pointer;
}

/* drop down overflow fix */

/* required field styling */
.nav {
  box-shadow: 1px 4px 13px 1px #969595;
}

.hint-table-span {
  color: #655959;
}

label {
  color: #2e6680;
  font-size: 15px;
  font-weight: 700;
}

label span {
  color: red;
  font-size: 22px;
  position: absolute;
  line-height: 20px;
  font-weight: 700;
}

.App-link {
  color: #09d3ac;
}

.home-header {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.bg-blue {
  background-color: #c3ceda;
}

.btn-blue {
  background-color: #327fb5;
  border-color: #327fb5;
  color: white;
}

#admin-dropdown {
  left: 0;
}

.color-text {
  color: #1d1f23 !important;
}

/*  Login Page CSS */
.login-container {
  margin-top: 5%;
  margin-bottom: 5%;
}

.login-form-1 {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-1 h3 {
  text-align: center;
  color: #333;
}

.login-form-2 {
  padding: 5%;
  background: #0062cc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-2 h3 {
  text-align: center;
  color: #fff;
}

.login-container form {
  padding: 10%;
}

.btnSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #0062cc;
}

.login-form-2 .btnSubmit {
  font-weight: 600;
  color: #0062cc;
  background-color: #fff;
}

.login-form-2 .ForgetPwd {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.login-form-1 .ForgetPwd {
  color: #0062cc;
  font-weight: 600;
  text-decoration: none;
}

.bg-dark-blue {
  background-color: #142d47;
  color: white;
}

.bg-light-blue {
  background-color: #3369a3;
  color: white;
}

.border-radius {
  border: none;
  border-radius: 11px;
}

.margin-left {
  margin-left: 240px !important;
}

.navbar-margin-right {
  margin-right: 80px;
}

.submit-btn {
  padding: 8px 0;
  border-radius: 5px;
  color: #3c3c3c;
  position: relative;
}

.spinner-border {
  width: 1.6rem !important;
  height: 1.6rem !important;
  margin-top: 10px;
  position: absolute;
}

.textArea {
  background: #e8e7e7;
  border-radius: 0;
  text-align: left;
  padding: 1px 10px;
}

.textArea p {
  margin: 6px 0;
}

.textArea p span {
  color: #00adee;
}

/* accordion styling */
.accordion > .card {
  background: #efefef;
  margin: 10px auto;
  box-shadow: 0 3px 5px 0 #a9a9a9;
  border-radius: 4px !important;
  padding: 0;
}

.unitBtns {
  max-width: 900px;
  margin: 15px auto !important;
}

.unitBtns .scopeBtn {
  display: flex;
}

.unitBtns .scopeBtn .btn {
  width: 33%;
  outline: none;
  padding: 2px 10px;
  box-shadow: 0 3px 5px 0 #666;
}

.unitBtns .scopeBtn .btn:last-of-type {
  width: 35%;
}

.unitBtns .scopeBtn .btn:active,
.unitBtns .btn-success:active {
  transform: translateY(3px);
}

.unitBtns .btn-success {
  padding: 13px 26px;
  box-shadow: 0 3px 5px 0 #666;
}

.route-loader {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.route-loader .spinner-border {
  margin-top: 0;
  width: 3.6rem !important;
  height: 3.6rem !important;
}

.form-control,
.css-yk16xz-control {
  border-radius: 0 !important;
}

.availablity label {
  margin: 7px 7px 0 0;
}

.availablity .form-control {
  width: 50%;
}

.d-inherit {
  display: inherit;
}

.tableLoader {
  padding: 10px 10px;
  position: relative;
  display: flex;
  justify-content: center;
}

.user-email a {
  color: #000000 !important;
}

.availablity-btn {
  margin-left: 8px;
  font-size: 16px;
  color: #204654 !important;
  cursor: pointer;
  font-weight: 700;
}

.modal-header svg {
  cursor: pointer !important;
}

.select-date {
  background-color: #0069d9;
  border-radius: 0 !important;
}

.select-date:focus {
  box-shadow: none !important;
}

.location-details {
  text-align: left;
  padding: 5px 15px 0 15px;
  cursor: pointer;
}

.location-details:hover {
  background-color: #f2f2f2;
}

.location-details hr {
  margin-bottom: 0;
}

.location-details p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.location-details span {
  color: #2e6680;
}

.table-container {
  max-height: 40vh;
  overflow-y: scroll;
}

.customer-add {
  max-height: 40px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .col-8 .w-75 {
    width: 100% !important;
  }

  .col-8 .ml-4,
  .col-8 .ml-3,
  .col-12 .ml-4 {
    margin-left: 0 !important;
  }

  .container-fluid .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .container-fluid .card-body .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .unitBtns .scopeBtn .btn,
  .unitBtns .scopeBtn .btn:last-of-type {
    width: 100%;
  }

  .unitBtns {
    flex-direction: column-reverse;
  }
}

.sidenav {
  height: calc(100vh - 70px);
  margin-top: 77px;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #2a333d;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  padding: 8px 0;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  display: block;
  border-bottom: 1px solid #212931;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main-nav {
  width: calc(100vw - 220px);
  margin-left: 200px;
}

.nav-top-margin {
  margin-top: 86px;
}

.overriden-hover:hover {
  box-shadow: 1px 4px 13px 1px #969595 !important;
}

.selected-link {
  font-weight: bold;
  background: #495766;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-balham .ag-ltr .ag-cell {
  text-align: center;
}

/* side nav styling */
.sidenav a:hover {
  text-decoration: none;
}

/* order history table styling */
.history thead {
  background: #f5f7f7;
}

.history thead tr th {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 600;
  font-size: 11px;
}

.history tbody tr td {
  font-size: 10px;
}

.safety {
  background: #327fb5;
  color: white;
}

.safety span:first-of-type {
  color: rgb(255, 255, 255) !important;
  font-weight: 900;
  font-size: 15px;
}

.safety span:last-of-type {
  font-size: 12px;
  padding: 0px 60px;
}

/* pagination styling */
.pagination {
  justify-content: center;
}

.pagination .previous {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffffff;
  background-color: #2a333d;
  border: 1px solid #2a333d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.pagination .next {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffffff !important;
  background-color: #2a333d;
  border: 1px solid #2a333d;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.pagination .next a,
.pagination .previous a {
  color: #ffffff;
}

.pagination .next a:hover,
.pagination .previous a:hover {
  text-decoration: none;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #48a2b8;
  border-color: #48a2b8;
}

.pagination .next:hover,
.pagination .previous:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #131313;
  border-color: #dee2e6;
}

.page-link:hover {
  color: white !important;
  background-color: #48a2b8 !important;
}

.pagination .page-link {
  color: #333;
  box-shadow: none;
  background-color: #e5e5e5;
  border: 1px solid #cacaca;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.btn-shadow {
  box-shadow: 1px 2px 3px 0px #666;
}

/* responsive for tablet */
@media (max-width: 991px) {
  .availablity .mb-4 .col-12 {
    justify-content: center;
  }

  .availablity .mb-4 .col-12:nth-of-type(2) {
    text-align: center;
    margin-top: 10px;
  }

  .dropdown-menu.show {
    display: inline-block;
    text-align: center;
    background: #f2f2f2;
  }
}

.portal-link {
  padding: 8px 10px !important;
  color: #706d7a;
  text-decoration: none;
}

.portal-link:hover {
  text-decoration: none;

  color: #706d7a;
}

.error {
  border: 1px solid red;
}

.fa-check {
  color: green;
  margin: 14px 0 0 10px;
}

.work-order-image {
  max-width: 400px;
  margin: 10px 0 0 9px;
}

.animated {
  animation: appear 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.master-reviews {
  display: flex;
  margin: 0 0 10px 0;
}

.master-reviews .orders {
  display: flex;
  padding: 10px 15px;
  background: blue;
  color: white;
  border-radius: 5px;
}

.master-reviews .orders:nth-of-type(1) p:nth-of-type(1) {
  font-size: 18px;
}

.master-reviews .orders p {
  margin-bottom: 0;
  padding: 0 0 0 8px;
  font-size: 24px;
  line-height: 32px;
}

.nav-dropdown > a {
  font-size: 16px;
  color: #706d7a;
}

.master-reviews .orders:nth-of-type(2) {
  margin: 0 12px;
  background: #019614;
}

.master-reviews .orders:nth-of-type(3) {
  background: #d83f05;
}

.calendar-weekly-table > thead > tr {
  background-color: transparent !important;
  color: #212529 !important;
  cursor: pointer;
}

.calendar-weekly-table > tbody > tr:nth-child(odd),
.calendar-weekly-table > tbody > tr:nth-child(even) {
  background-color: transparent !important;
}

.calendar-weekly-table > tbody h6 {
  border: 1px solid white;
  margin: 0;
  color: white;
  padding: 2px 0;
}

.calendar-weekly-table > tbody td {
  vertical-align: top !important;
  padding: 0;
  /* min-width: 300px; */
}

.calendar-daily-table tbody > .daily-status {
  color: white;
}

.calendar-daily-table thead > tr {
  background: #4472c4;
  color: white;
}

.calendar-daily-table tbody > tr > .daily-price-tubs {
  color: white;
}

.calendar-daily-table tbody > tr > .daily-address {
  background: #d9d9d9;
  color: black;
}

.calendar-daily-table td {
  padding: 0;
  font-weight: 600;
}

.work-queue-modal-loader {
  height: 400px;
}

.customers-table-body {
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.customers-table-body > tr {
  width: 100%;
  table-layout: fixed;
}

.customers-table-body > tr:hover {
  cursor: pointer;
}

.increment-decrement-icon {
  cursor: pointer;
}

.csv-file-input > input {
  display: none;
}

.csv-file-input {
  height: 40px;
  background: #4e555b;
  display: table;
  color: #fff;
  padding: 0px 0px 0px 8px;
}

.work-order-detail-card {
  background: #efefef !important;
  margin: 10px auto;
  box-shadow: 0 3px 5px 0 #a9a9a9;
  border-radius: 4px !important;
  padding: 15px;
}

.sticky-weekly-header {
  background: white;
  position: sticky;
  top: -1px;
  /* Don't forget this, required for the stickiness */
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

.hide-wo-button {
  position: sticky;
  top: 10px;
}

.tab-without-unit {
  background-color: red !important;
}

.white-space-pre {
  white-space: pre-line;
}

.resolve-button {
  /* position: absolute;
  top: -4px; */
  padding: 1px 10px !important;
  height: calc(1.5em + 0.75rem + 2px);
  width: 150px;
}

.cursor-p {
  cursor: pointer;
}

.customer-table {
  height: 500px;
  overflow: auto;
}

.customer-table > table tbody td {
  cursor: pointer;
}

.btn-blue {
  background-color: #327fb5 !important;
  border-color: #327fb5 !important;
  color: white !important;
}

.update-notes-btn {
  height: 35px;
  align-self: flex-end;
  width: 125px;
}

.need-date-box {
  background: red !important;
  padding: 5px 10px;
  color: white;
  border: 1px solid black;
}

.work-order-notes-tab > a {
  font-size: 14px;
}

.work-order-close-btn {
  background: #dc3545 !important;
}

.fixed-close-btn-container {
  position: fixed !important;
  width: 600px !important;
  height: 200px;
  left: 50%;
  top: 154px;
  margin-left: 568px !important;
}

.fixed-close-btn-container > button {
  width: 175px;
}

.fixed-submit-btn-container {
  position: fixed !important;
  width: 600px !important;
  height: 200px;
  left: 50%;
  top: 30px;
  margin-left: 355px;
  z-index: -1;
}

.fixed-submit-btn-container > button {
  width: 190px;
  margin-bottom: 5px;
}
.extra-team-label {
  color: #2e6680;
  margin-left: 20%;
  margin-top: 2%;
}

.wo-detail-header > .close {
  display: none;
}

.notes-tab {
  height: 180px !important;
}

.filter-label {
  width: 205px;
  text-align: left;
}

.notesal {
  margin-top: 13px;
  margin-right: 2px !important;
}
.notes-times-icon {
  color: red;
  margin-left: 96%;
  margin-bottom: -7%;
}

/* .ellipsis-icon {
  width: 200px;
} */

.map-side-table {
  height: 78vh;
  width: 20%;
  overflow: scroll;
}

.map-side-table-header-text {
  width: 10%;
}

.map-side-table-header {
  background-color: #4472c4;
  color: rgb(255, 255, 255);
}

.map-side-table-body {
  background-color: #e9ebf5;
}

.notes-times-icon-db {
  color: red;
  margin-left: 94%;
  margin-top: -5%;
}
.mlft-75 {
  margin-left: 76%;
  margin-top: 1.5%;
}
.mlr1 {
  margin-left: 1.5% !important;
  margin-right: 1.5% !important;
}
.mlft-43 {
  margin-left: 43%;
}
.mt-2 {
  margin-top: 2px;
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  color: #707070;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input-area {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #707070;
  border-radius: 4px;
  resize: none;
  box-sizing: border-box;
}

.did-floating-input,
.did-floating-select {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #707070;
  border-radius: 4px;
  box-sizing: border-box;
}

.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
}

.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select:not([value='']):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select[value='']:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}

.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}

.did-error-input .did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

.search-container {
  padding-bottom: 5px;
}

.datePicker {
  border: 1px solid #ced4da;
  color: #495057;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
